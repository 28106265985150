<template>
    <onboarding-layout
        :loading="true"
        :error-text="errorText"
    />
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { getApplicantReturning, getNextCryptoApplicationAction } from '@/services/api'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { originationPagePaths } from '@/routes/originationRoutes'
    import { Flows } from '@/flow/flowController'
    import assert from 'assert'
    import { NextCryptoApplicationAction } from '@/utils/constants'
    import { originationFlow } from '@/flow/originationFlow'
    import authMixin from '../../mixins/authMixin'

    export default {
        components: { OnboardingLayout },
        props: {
            returnToken: {
                type: String,
                required: true,
            },
        },
        mixins: [authMixin],
        data() {
            return {
                loading: false, // needed for assert in authMixin
                errorText: '',
            }
        },
        mounted: async function () {
            this.$logEvent('view_origination_return', {
                returnToken: this.returnToken,
            })
            try {
                if (appSessionStorage.getItem(localStorageKey.jwtTokens)) {
                    logger.info(`User hit origination return to anything page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                    appSessionStorage.clear()
                    window.location.reload()
                    return
                }

                const applicantReturningResponse = await getApplicantReturning(this.returnToken)
                logger.info(`Received get applicant returning response upon origination return to anything: ${JSON.stringify(applicantReturningResponse.data)}`)

                if (applicantReturningResponse.data.error === 'PRIOR_APPLICATION_FOUND') {
                    assert(applicantReturningResponse.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${applicantReturningResponse.data.payload.returnToken2}`)
                    return await this.$router.push({ path: sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION, query: { p: applicantReturningResponse.data.payload.returnToken2 } })
                }

                if (applicantReturningResponse.data.error === 'APPLICATION_EXPIRED') {
                    const data = applicantReturningResponse.data
                    assert(data.payload.loanApplicationStatus, 'no loanApplicationStatus provided for expired loan application')
                    logger.info(`Loan application ${data.payload.loanApplicationId} is expired, redirecting applicant to start a new application`)
                    const startPagePath = appSessionStorage.getItem(localStorageKey.startPagePath)

                    // clear the session storage so we can start a new application, keep info needed for context
                    appSessionStorage.clearWithException(['loanApplicationId', 'phoneNumber'])
                    appSessionStorage.setItem(localStorageKey.landingWarning, 'Your prior application expired. Please start a new one.')

                    return (window.location.href = startPagePath || '/')
                }

                // We assume that the user is in the origination flow, which is true most of the
                // time even if they are a direct mail applicant. Only if a direct mail applicant
                // is returning to the pre-qual page will we change the flow to mailerOrigination,
                // so they can complete that flow, then move to origination.
                appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)

                const nextApplicationActionResponse = await getNextCryptoApplicationAction()
                const nextApplicationAction = nextApplicationActionResponse.data.payload.nextApplicationAction
                logger.log(`Next application action is ${nextApplicationAction}`)

                if (nextApplicationAction === NextCryptoApplicationAction.WELCOME) {
                    return await this.$router.push({ path: originationPagePaths.WELCOME })
                } else if (nextApplicationAction === NextCryptoApplicationAction.DENIED) {
                    return await this.$router.push({ path: sharedPagePaths.LOAN_NOT_APPROVED })
                } else if (nextApplicationAction === NextCryptoApplicationAction.OFFER_PREVIEW) {
                    return await this.$router.push({ path: originationPagePaths.OFFER_PREVIEW })
                } else if (nextApplicationAction === NextCryptoApplicationAction.IDENTITY_VERIFICATION) {
                    return await this.$router.push({ path: originationPagePaths.IDENTITY_VERIFICATION })
                } else if (nextApplicationAction === NextCryptoApplicationAction.SIGN_DOCUMENTS) {
                    return await this.$router.push({ path: originationPagePaths.SIGN_DOCUMENTS, query: this.$route.query })
                } else if (nextApplicationAction === NextCryptoApplicationAction.PENDING) {
                    return await this.$router.push({ path: originationFlow[0] })
                } else if (nextApplicationAction === NextCryptoApplicationAction.REVIEW) {
                    return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                } else if (nextApplicationAction === NextCryptoApplicationAction.THANKS) {
                    return await this.$router.push({ path: sharedPagePaths.THANKS })
                } else {
                    logger.error(`Unexpected next applicant action: ${nextApplicationAction}. Sending to thanks page`)
                    return await this.$router.push({ path: sharedPagePaths.THANKS })
                }
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
    }
</script>
